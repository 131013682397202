import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import { Button, ButtonGroup, Input, Dialog, Select, Option, Form, FormItem, Upload} from 'element-ui';

Vue.use(Button)
.use(ButtonGroup)
.use(Input)
.use(Dialog)
.use(Select)
.use(Option)
.use(Form)
.use(FormItem)
.use(Upload)
 
// 控制根元素字体大小
import remObj from './utils/rem'
// 初始化是设置根元素大小
remObj.initRootEvent();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
