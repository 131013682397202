// 目的：设置html元素的font-size属性
export default {
    initRootEvent(){
        let init = ()=> {
            let w = document.documentElement.offsetWidth || window.innerWidth;
            if(w >= 375) 
            document.documentElement.style.fontSize="37.5px";
        }
        window.addEventListener('resize',init);
        window.addEventListener('load',init);
        init();
    }
}